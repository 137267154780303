/* eslint react/prop-types: 0 */
import { BaseModal } from '../../../../build/assessment/EditorComponents/BaseModal';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { CyclesContext } from '../../../contact/ContactContexts';
import { ConfigEdit } from './ConfigEdit';
import { useLazyValidatePhaseInvites } from '../CyclesHooks';

export const ConfigModal = memo(function ConfigModal (
  {
    updateInviteConfig,
    inviteConfigTemplate,
    offerOption,
    applicants
  }
) {
  const [showing, setShowing] = useState(offerOption)
  const cycles = useContext(CyclesContext)
  const [trigger, , validationQuerying] = useLazyValidatePhaseInvites()

  const validateValuesWithApplicants = useCallback((values) => {
    console.info('Submitting invite config template form data for validation.', { values, applicants })
    return trigger({ phaseId: values?.phase ?? '0', applicantIds: applicants, cycleIds: cycles })
      .unwrap()
      .then(response => {
        console.debug('Got validate invite config template response', { response })
        notifications.show(
          {
            color: 'green',
            title: 'Invite Validated',
            message: 'Your phase invite configuration looks good!',
            autoClose: 5000
          }
        )
        setShowing(false)
        updateInviteConfig(values)
        console.debug('Exiting validate callback', { values, applicants })
      })
      .catch(err => {
        console.error('Got validate invite config template error', { err })
        notifications.show(
          {
            color: 'red',
            title: 'Phase Invite Validation Error',
            message: 'Your phase invite configuration cannot be used for all ' + (applicants?.length ? 'selected applicants.' : 'selected cycles.'),
            autoClose: 7000
          }
        )
      })
  }, [applicants, cycles, updateInviteConfig, trigger])

  const onCancel = useCallback(() => {
    setShowing(false)
  }, [])

  useEffect(() => {
    if (offerOption) {
      console.debug('Invite config modal auto-opening', { offerOption })
      setShowing(true)
    }
  }, [offerOption])

  console.debug('ConfigModal updating', { offerOption, cycles, applicants, inviteConfigTemplate, showing })

  return (
    <>
      {!!offerOption && !!inviteConfigTemplate && (
        <Button variant='subtle' size='sm' color='gray.6' onClick={() => updateInviteConfig(null)}>Remove Phase Invite</Button>)}
      {!!offerOption && <Button variant='subtle' size='sm' onClick={() => setShowing(true)}>{inviteConfigTemplate ? 'Edit' : 'Use'} Phase Invite</Button>}
      <BaseModal
        title=''
        showing={showing}
        onClose={() => setShowing(false)}
        withCloseButton={false}
        size='auto'
      >
        <ConfigEdit
          cycles={cycles}
          template={inviteConfigTemplate}
          onSubmit={validateValuesWithApplicants}
          onCancel={onCancel}
          validating={validationQuerying}
        />
      </BaseModal>
    </>
  )
})
