import React, { useEffect, useMemo, useReducer } from 'react';
import {
  ContactContext,
  ContactDispatchContext,
  ContactQueryErrorContext,
  ContactQueryingContext
} from './ContactContexts';
import { contactStateReducer, ContactStateUpdate, createInitialContactState } from './ContactState';
import { useGetCycleBatteriesLastSubmissionsQuery } from '../../../redux/query/hire/cyclesApi.slice';
import { useGetHiringViewBatteriesLastSubmissionsQuery } from '../../../redux/query/hire/hiringViewsApi.slice';
import { useValidatePhaseInvitesQuery } from '../cycle/invites/sent/AssessmentInvitesApi';

/**
 * @param {array} batteries
 * @param {array} applicants
 * @param {array} statuses
 * @param {array} emailTemplates
 * @param {array} textTemplates
 * @param {int[]} courtesyLetterRecipients
 * @param {int[]} cycleIds
 * @param {int} aggregateId hiringView or cycle id
 * @param {int} maxSelectedApplicants
 * @param {boolean} isHiringView
 * @param {?(int[])} preSelectedIds
 * @param children
 */
export function ContactStateProvider (
  {
    batteries,
    applicants,
    statuses,
    emailTemplates,
    textTemplates,
    courtesyLetterRecipients,
    cycleIds,
    aggregateId,
    maxSelectedApplicants,
    isHiringView = false,
    preSelectedIds = null,
    children
  }
) {
  const [state, dispatch] = useReducer(
    contactStateReducer,
    {
      batteries,
      applicants,
      statuses,
      emailTemplates,
      textTemplates,
      courtesyLetterRecipients,
      cycleIds,
      aggregateId,
      maxSelectedApplicants,
      isHiringView,
      preSelectedIds
    },
    createInitialContactState
  )
  const [, submissionsQuerying, submissionsError] = useContactBatterySubmissions(state, dispatch)
  const [, validationQuerying, validationError] = useContactPhaseSubmissions(state, dispatch)

  console.debug(
    'ContactStateProvider updated',
    { state, submissionsError, validationError, submissionsQuerying, validationQuerying }
  )

  return (
    <ContactDispatchContext.Provider value={dispatch}>
      <ContactQueryErrorContext.Provider value={(!!state.batteryId && submissionsError) || (!!state.inviteConfigTemplate?.phase && validationError)}>
        <ContactQueryingContext.Provider value={submissionsQuerying || validationQuerying}>
          <ContactContext.Provider value={state}>
            {children}
          </ContactContext.Provider>
        </ContactQueryingContext.Provider>
      </ContactQueryErrorContext.Provider>
    </ContactDispatchContext.Provider>
  )
}

/**
 * @param {Contact.ContactState} state
 * @param {Function} dispatch
 * @returns {[?array, boolean, boolean]}
 */
function useContactBatterySubmissions (state, dispatch) {
  const [lastSubmissions, submissionsQuerying, submissionsError] = useBatteryLastSubmissions(
    state.aggregateId,
    state.batteryId,
    state.isHiringView
  )
  useEffect(() => {
    console.debug('Last submissions or battery changed', { lastSubmissions: lastSubmissions, batteryId: state.batteryId })
    if (lastSubmissions?.data && state.batteryId) {
      dispatch({ type: ContactStateUpdate.UpsertBatterySubmission, batteryId: state.batteryId, values: lastSubmissions.data })
    }
  }, [lastSubmissions, state.batteryId, dispatch])

  return [lastSubmissions, submissionsQuerying, submissionsError]
}

/**
 * @param {int|string} aggregateId
 * @param {int|string|null} batteryId
 * @param {boolean} isHiringView
 * @returns {[?Object.<{data: array}>, boolean, boolean]}
 */
function useBatteryLastSubmissions (aggregateId, batteryId, isHiringView) {
  const { currentData: cycleData, isFetching: cycleQuerying, isError: cycleError } = useGetCycleBatteriesLastSubmissionsQuery(
    { cycleId: aggregateId, batteryId: batteryId },
    { skip: !(batteryId && !isHiringView && aggregateId) }
  )

  const { currentData: viewData, isFetching: viewQuerying, isError: viewError } = useGetHiringViewBatteriesLastSubmissionsQuery(
    { viewId: aggregateId, batteryId: batteryId },
    { skip: !(batteryId && isHiringView && aggregateId) }
  )

  return isHiringView ? [viewData, viewQuerying, viewError] : [cycleData, cycleQuerying, cycleError]
}

/**
 *
 * @param {Contact.ContactState} state
 * @param {Function} dispatch
 * @returns {[object, boolean, boolean]}
 */
function useContactPhaseSubmissions (state, dispatch) {
  const phaseId = state.inviteConfigTemplate?.phase
  const cycleIds = state.cycleIds
  const applicantIds = useMemo(() => {
    if (!phaseId) {
      return []
    }

    const phaseRetakes = state.phaseRetakes.get(parseInt(phaseId))
    const selectedIdsMissingSubmissionDataForPhase = [...state.filteredApplicants.entries()]
      .filter(([applicantId, selected]) => !!selected && !phaseRetakes?.has(applicantId))
      .map(([applicantId]) => applicantId)

    console.debug('Updated applicant ids for phase query', { selectedIdsMissingSubmissionDataForPhase })
    return selectedIdsMissingSubmissionDataForPhase
  }, [phaseId, state.phaseRetakes, state.filteredApplicants])

  const { currentData: validationData, isFetching: validationQuerying, isError: validationError } = useValidatePhaseInvitesQuery(
    { phaseId, applicantIds, cycleIds },
    { skip: !(phaseId && cycleIds.length && applicantIds.length) }
  )

  useEffect(() => {
    console.debug('validationData or phase changed', { validationData, phaseId })
    if (validationData && phaseId) {
      dispatch({ type: ContactStateUpdate.UpsertPhaseSubmission, phaseId: parseInt(phaseId), values: validationData })
    }
  }, [validationData, phaseId, dispatch])

  return [validationData, validationQuerying, validationError]
}
