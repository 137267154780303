import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags, providesList } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'
import _ from 'lodash'

export const hiringViewsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHiringViews: builder.query({
      query: (params) => `hiring-views${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.HiringViews, id: id })),
              { type: ApiTags.HiringViews, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.HiringViews, id: ApiAbstractIds.PartialList }]
    }),
    getHiringViewDetail: builder.query({
      query: id => `hiring-views/${id}/details`,
      providesTags: (result, error, id) => [{ type: ApiTags.HiringViews, id: id }]
    }),
    deleteHiringView: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `hiring-views/${id}`,
        method: ApiMethods.Delete,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringViews, id: id }, { type: ApiTags.HiringViewsDetails, id: id }]
    }),
    getHiringViewCycles: builder.query({
      query: ({ id, ...params }) => `hiring-views/${id}/details${paramsToQuery(params)}`,
      transformResponse: (response) => ({
        items: response.cycles, page: 1, limit: _.size(response.cycles), total: _.size(response.cycles)
      }),
      providesTags: (result, error, { id: hiringViewId }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.HiringViewsCycles, id: id })),
              { type: ApiTags.HiringViewsDetails, id: hiringViewId }
            ]
          : [{ type: ApiTags.HiringViewsDetails, id: hiringViewId }]
    }),
    getHiringViewEmailTemplates: builder.query({
      query: (viewId) => `hiring-views/${viewId}/templates`,
      providesTags: (result, error, viewId) => [
        { type: ApiTags.HiringViews, id: viewId },
        ...providesList(result, ApiTags.EmailTemplates)
      ]
    }),
    getHiringViewTextTemplates: builder.query({
      query: (viewId) => `hiring-views/${viewId}/text-templates`,
      providesTags: (result, error, viewId) => [
        { type: ApiTags.HiringViews, id: viewId },
        ...providesList(result, ApiTags.TextTemplates)
      ]
    }),
    getHiringViewBatteries: builder.query({
      query: (viewId) => `hiring-views/${viewId}/batteries`,
      providesTags: (result, error, viewId) => [
        { type: ApiTags.HiringViews, id: viewId },
        ...providesList(result, ApiTags.Batteries)
      ]
    }),
    getHiringViewBatteriesLastSubmissions: builder.query({
      query: ({ viewId, batteryId }) => `hiring-views/${viewId}/batteries/${batteryId}/last-submissions`,
      providesTags: (result, error, { viewId, batteryId }) => [
        { type: ApiTags.HiringViews, id: viewId },
        { type: ApiTags.Batteries, id: batteryId },
        ...providesList(result, ApiTags.BatterySubmissions)
      ]
    }),
    getHiringViewApplicants: builder.query({
      query: ({ viewId, limit = -1, ...params }) => `hiring-views/${viewId}/applicants${paramsToQuery({ ...limit, ...params })}`,
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: ApiTags.Applicants, id: id })),
              { type: ApiTags.Applicants, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Applicants, id: ApiAbstractIds.PartialList }]
    }),
    getHiringViewCourtesyLetterRecipients: builder.query({
      query: (viewId) => `hiring-views/${viewId}/courtesy-letter-recipients`,
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map((id) => ({ type: ApiTags.Applicants, id: id })),
              { type: ApiTags.Applicants, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Applicants, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const {
  useGetHiringViewsQuery,
  useGetHiringViewDetailQuery,
  useDeleteHiringViewMutation,
  useGetHiringViewCyclesQuery,
  useGetHiringViewEmailTemplatesQuery,
  useGetHiringViewTextTemplatesQuery,
  useGetHiringViewBatteriesQuery,
  useGetHiringViewBatteriesLastSubmissionsQuery,
  useGetHiringViewApplicantsQuery,
  useGetHiringViewCourtesyLetterRecipientsQuery
} = hiringViewsApi
