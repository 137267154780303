import React from 'react'
import { Center, Loader } from '@mantine/core'

function Loading () {
  return (
    <Center><Loader/></Center>
  )
}

export default Loading
