/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useContext, useMemo, useState } from 'react'
import SelectTable from './SelectTable'
import Form from './Form'
import { Checkbox, Flex, Grid, Group, MultiSelect, Text, TextInput } from '@mantine/core'
import * as styles from './Contact.module.scss'
import { ContactContext, ContactDispatchContext } from './ContactContexts';
import { ContactStateUpdate, useContactValidApplicants } from './ContactState';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCircleDotted } from '@tabler/icons-react';

export default function ContactMultipleApp ({ onSuccessRedirect }) {
  const state = useContext(ContactContext)
  const dispatch = useContext(ContactDispatchContext)
  const [unselected, selected] = useContactValidApplicants(state.filteredApplicants, state.applicants)
  const [applicantSearch, setApplicantSearch] = useState('')
  const [debouncedApplicantSearch] = useDebouncedValue(applicantSearch, 300)

  console.debug('Contact multiple app updating', { state, onSuccessRedirect, dispatch })

  const maxCount = state.maxSelectedApplicants
  const checked = !unselected.length || (selected.length === maxCount)
  const maxAllowedToAdd = Math.min(Math.max(maxCount - selected.length, 0), unselected.length)

  const statusOptions = useMemo(() => {
    return [...state.statuses.values()].map(status => ({ label: status.name, value: status.id.toString() }))
  }, [state.statuses])

  const selectedStatuses = useMemo(() => {
    return [...state.selectedStatuses.values()].map(statusId => statusId.toString())
  }, [state.selectedStatuses])

  const onStatusesChange = useCallback((newStatusIds) => {
    dispatch({ type: ContactStateUpdate.SetSelectStatuses, statusIds: newStatusIds.map(statusId => parseInt(statusId)) })
  }, [dispatch])

  const searchedUnselected = useMemo(() => {
    const searchFilter = debouncedApplicantSearch ? debouncedApplicantSearch.trim().toLowerCase() : null
    return !searchFilter ? unselected : unselected.filter(applicant => applicant.searchKey.includes(searchFilter))
  }, [debouncedApplicantSearch, unselected])

  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12 }}>
          <div className={styles.emailControls}>
            <Grid>
              <Grid.Col span={{ sm: 12, md: 6, lg: 3 }}>
                <MultiSelect
                  label='Status filters'
                  data={statusOptions}
                  value={selectedStatuses}
                  onChange={onStatusesChange}
                  clearable
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ sm: 12, lg: 10 }}>
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 12 }} order={{ xs: 2, sm: 1 }}>
                    <Checkbox
                      checked={state.hideCourtesyLetterRecipients}
                      onChange={() => dispatch({ type: ContactStateUpdate.ToggleHideCourtesyLetterApplicants })}
                      label='Hide applicants that were sent a courtesy letter'
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 'sm', sm: 12, md: 6 }} order={{ xs: 'sm', sm: 3, md: 2 }}>
                    <Group justify='space-between'>
                      <Checkbox
                        checked={checked}
                        onChange={() => dispatch({ type: ContactStateUpdate.ToggleSelectMaxApplicants })}
                        label={checked ? 'Unselect All' : `Select Max (${maxAllowedToAdd})` }
                      />
                      <TextInput
                        placeholder='Search name or email'
                        value={applicantSearch}
                        onChange={(event) => setApplicantSearch(event.currentTarget.value)}
                        rightSection={applicantSearch !== debouncedApplicantSearch ? <IconCircleDotted /> : null}
                        pb={5}
                      />
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6 }} order={{ xs: 1, sm: 2, md: 3 }}>
                    <Flex justify='flex-end' align='center' direction='row' wrap='nowrap'>
                      <Text>Applicants Selected: {selected.length}</Text>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </div>
          <Grid>
            <Grid.Col span={{ sm: 12, md: 6, lg: 5 }}>
              <SelectTable applicants={searchedUnselected} callback={(applicant) => dispatch({ type: ContactStateUpdate.ToggleSelectApplicant, applicant: applicant })} recipients={state.courtesyLetterRecipients} />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6, lg: 5 }}>
              <SelectTable applicants={selected} callback={(applicant) => dispatch({ type: ContactStateUpdate.ToggleSelectApplicant, applicant: applicant })} recipients={state.courtesyLetterRecipients} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ sm: 12, lg: 10 }}>
          <Form
            onSuccessRedirect={onSuccessRedirect}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
