/* eslint react/prop-types: 0 */
import { useCallback, useEffect, useRef, useState } from 'react'

export function useDebouncedField (globalContent, globalContentUpdater) {
  const [localContent, updateLocalContent] = useState(globalContent)
  const lastGlobalContentRef = useRef(globalContent)
  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      if (localContent !== globalContent) {
        if (lastGlobalContentRef.current === globalContent) {
          console.debug('Performing debounced timeout update.', { localContent, globalContent, timeoutId })
          lastGlobalContentRef.current = localContent
          globalContentUpdater(localContent)
        } else {
          console.debug('Skipping debounced update from global state change.', { localContent, globalContent, timeoutId })
          lastGlobalContentRef.current = globalContent
          updateLocalContent(globalContent)
        }
      }
    }, 2000)

    return () => {
      console.debug('Cancelling timeout update.', localContent, timeoutId)
      window.clearTimeout(timeoutId)
    }
  }, [localContent, globalContent, globalContentUpdater])

  const onBlur = useCallback((event) => {
    console.debug('On blur - debounced field immediately updating.', event.currentTarget?.value)
    const eventContent = event.currentTarget.value
    if (globalContent !== eventContent) {
      lastGlobalContentRef.current = eventContent
      globalContentUpdater(eventContent)
    }
  }, [globalContent, globalContentUpdater])

  return { value: localContent, onChange: (event) => updateLocalContent(event.currentTarget?.value !== undefined ? event.currentTarget.value : event), onBlur: (event) => onBlur(event) }
}

export function useUpdateOnDebounceOrSubmitField (globalContent, globalContentUpdater, updateGlobalContentFlag) {
  const [localContent, updateLocalContent] = useState(globalContent)
  const lastGlobalContentRef = useRef(globalContent)

  useEffect(() => {
    if ((localContent !== globalContent) && (lastGlobalContentRef.current !== globalContent)) {
      console.debug('Performing immediate local update from global content change.', { globalContent, localContent })
      lastGlobalContentRef.current = globalContent
      updateLocalContent(globalContent)
      return
    }
    const timeoutId = window.setTimeout(() => {
      if (localContent !== globalContent) {
        if (lastGlobalContentRef.current === globalContent) {
          console.debug('Performing debounced timeout update.', { localContent, globalContent, timeoutId })
          lastGlobalContentRef.current = localContent
          globalContentUpdater(localContent)
        } else {
          console.debug('Skipping debounced update from global state change.', { localContent, globalContent, timeoutId })
          lastGlobalContentRef.current = globalContent
          updateLocalContent(globalContent)
        }
      }
    }, 2000)

    return () => {
      console.debug('Cancelling timeout update.', localContent, timeoutId)
      window.clearTimeout(timeoutId)
    }
  }, [localContent, globalContent, globalContentUpdater])

  const onBlur = useCallback((event) => {
    const newValue = event?.currentTarget?.value !== undefined ? event.currentTarget.value : event
    console.debug('On blur - debounced field immediately updating.', { event, newValue })
    if (globalContent !== newValue) {
      lastGlobalContentRef.current = newValue
      globalContentUpdater(newValue)
    }
  }, [globalContent, globalContentUpdater])

  const onChange = useCallback((event) => {
    const newValue = event?.currentTarget?.value !== undefined ? event.currentTarget.value : event
    console.debug('On change - debounced field queuing update.', { event, newValue })
    updateLocalContent(newValue)
  }, [])

  useEffect(() => {
    if (updateGlobalContentFlag) {
      console.debug('On update flag - debounced field immediately updating.', { localContent, globalContent })
      if (globalContent !== localContent) {
        lastGlobalContentRef.current = localContent
        globalContentUpdater(localContent)
      }
    }
  }, [updateGlobalContentFlag, localContent, globalContent, globalContentUpdater])

  return { value: localContent, onChange: onChange, onBlur: onBlur }
}

export function useUpdateOnSubmitField (globalContent, globalContentUpdater, updateGlobalContentFlag) {
  const [localContent, updateLocalContent] = useState(globalContent)
  const localContentToGlobalRef = useRef(localContent)

  useEffect(() => {
    // I'm not 100% sure if the useRef is necessary for the bottom useEffect to have the proper 'current' localContent
    // without listing localContent as a dependency, but I'm pretty sure that this will work.
    if (localContent !== localContentToGlobalRef.current) {
      localContentToGlobalRef.current = localContent
    }
  }, [localContent])

  useEffect(() => {
    // Allow overwriting local content from global content.
    // Consumers of this class should be aware that they are discarding all local changes when changing the global
    // content without first fetching local changes by modifying updateGlobalContentFlag.
    if (localContentToGlobalRef.current !== globalContent) {
      localContentToGlobalRef.current = globalContent // Can be removed if useRef not necessary.
      updateLocalContent(globalContent)
    }
  }, [globalContent])

  useEffect(() => {
    // This is where the magic happens. Global content is only synced with local changes when flag is modified.
    if (globalContent !== localContentToGlobalRef.current) {
      console.debug(
        'Updating global content with local content due to change in either global content or submit flag.',
        localContentToGlobalRef.current,
        globalContent,
        updateGlobalContentFlag
      )
      globalContentUpdater(localContentToGlobalRef.current)
    }
  }, [globalContent, updateGlobalContentFlag, globalContentUpdater])

  return [localContent, updateLocalContent]
}

/**
 * Example usage:
 *
 * export function UpdateOnSubmitFieldConsumer ({ content, updateContent, updateContentFlag }) {
 *   const [displayContent, updateDisplayContent] = useUpdateOnSubmitField(content, updateContent, updateContentFlag)
 *
 *   useLogger(
 *     'UpdateOnSubmitFieldConsumer',
 *     [content, displayContent, updateContentFlag]
 *   )
 *
 *   return (
 *     <TextInput
 *       value={displayContent}
 *       placeholder='Type something here and the parent will not render.'
 *       onChange={(event) => updateDisplayContent(event.currentTarget.value)}
 *     />
 *   )
 * }
 *
 * export function FormComponentWithManySubComponents () {
 *   const [submitCounter, setSubmitCounter] = useState(0)
 *   const [submitNextChange, setSubmitNextChange] = useState(false)
 *   const [fieldOne, setFieldOne] = useState('One')
 *   const [fieldTwo, setFieldTwo] = useState('Two')
 *   const [fieldThree, setFieldThree] = useState('Three')
 *
 *   useEffect(() => {
 *     if (submitNextChange) {
 *       console.info('submitNextChange true - would be submitting data.', fieldOne, fieldTwo, fieldThree)
 *       setSubmitNextChange(false)
 *     }
 *   }, [submitNextChange, fieldOne, fieldTwo, fieldThree, setSubmitNextChange])
 *
 *   useEffect(() => {
 *     // Here you could use a next render hook as is implemented below to fetch values after the global content has changed.
 *     // You could instead use a allUpdated flag ref with a useEffect dependent upon all fieldValues,
 *     // or a variety of other approaches to recognizing whether all changes had been synced.
 *     // e.g. const allUpdated = useRef(true); const doSubmit = useRef(false)
 *     // Modify onClick to set allUpdated.current = false as well, and doSubmit.current = true
 *     // When any field value changes, allUpdated.current = true in the effect.
 *     // If doSubmit.current is also true, send data in request and set doSubmit.current = false.
 *     console.info('Submit counter changed.', submitCounter)
 *     setSubmitNextChange(true)
 *   }, [submitCounter, setSubmitNextChange])
 *
 *   useLogger(
 *     'FormComponentWithManySubComponents',
 *     [submitNextChange, submitCounter, fieldOne, fieldTwo, fieldThree]
 *   )
 *
 *   return (
 *     <div>
 *       <UpdateOnSubmitFieldConsumer
 *         key={1}
 *         content={fieldOne}
 *         updateContent={setFieldOne}
 *         updateContentFlag={submitCounter}
 *       />
 *       <UpdateOnSubmitFieldConsumer
 *         key={2}
 *         content={fieldTwo}
 *         updateContent={setFieldTwo}
 *         updateContentFlag={submitCounter}
 *       />
 *       <UpdateOnSubmitFieldConsumer
 *         key={3}
 *         content={fieldThree}
 *         updateContent={setFieldThree}
 *         updateContentFlag={submitCounter}
 *       />
 *       <Button color='blue' onClick={() => setSubmitCounter((prev) => prev + 1)} >Submit</Button>
 *     </div>
 *   )
 * }
 */
