/* eslint react/prop-types: 0 */
import { ActionIcon, Group, Indicator, SimpleGrid, Tooltip } from '@mantine/core'
import { IconDeviceMobileMessage, IconMail, IconPlus } from '@tabler/icons-react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useContext } from 'react'
import { useAccount } from '../../util/Hooks'
import Email from './Email'
import TextMessage from './TextMessage'
import { ContactStateUpdate, MessageType } from './ContactState';
import { ContactDispatchContext } from './ContactContexts';

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Messages ({ messages, form, templates, textTemplates, previewApplicant }) {
  return (
    <SimpleGrid cols={1}>
      {messages.map((message, index) => (
        <div key={message.id}>
          {message.type === MessageType.Email && (
            <Email message={message} form={form} index={index} templates={templates} previewApplicant={previewApplicant} />
          )}
          {message.type === MessageType.Text && (
            <TextMessage message={message} form={form} index={index} templates={textTemplates} previewApplicant={previewApplicant} />
          )}
        </div>
      ))}
      <Group spacing='xs'>
        <AddEmailMessage />
        <AddTextMessage />
      </Group>
    </SimpleGrid>
  )
}

function AddEmailMessage () {
  const dispatch = useContext(ContactDispatchContext)
  return (
    <Tooltip label='Add email'>
      <Indicator color='success' variant='transparent' offset={2} position='bottom-end' label={<IconPlus size='1rem' strokeWidth='4' />}>
        <ActionIcon
          variant='light'
          color='success'
          onClick={() => dispatch({ type: ContactStateUpdate.CreateNewMessage, messageType: MessageType.Email })}
          >
            <IconMail/>
        </ActionIcon>
      </Indicator>
    </Tooltip>
  )
}

function AddTextMessage () {
  const dispatch = useContext(ContactDispatchContext)
  const account = useAccount()

  return (
    (!!account?.access.ADMIN) &&
    <Tooltip label='Add text message'>
      <Indicator color='success' variant='transparent' position='bottom-end' offset={2} label={<IconPlus size='1rem' strokeWidth='4' />}>
        <ActionIcon
          variant='light'
          color='success'
          onClick={() => dispatch({ type: ContactStateUpdate.CreateNewMessage, messageType: MessageType.Text })}
          >
            <IconDeviceMobileMessage/>
        </ActionIcon>
      </Indicator>
    </Tooltip>
  )
}
