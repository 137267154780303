import { IconRefresh } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorAlert from './ErrorAlert';
import { ActionIcon, Center } from '@mantine/core';

export default function QueryErrorAlert (
  {
    children,
    title = 'Loading Error!', // Alert prop.
    loading = false, // Icon prop.
    disabled = false, // Icon prop.
    onClick = null, // Icon prop.
    autoContrast = false, // Used for both icon and alert.
    size = 'md', // Used for icon only.
    onClose = null, // Alert prop, sets withCloseButton to true by default if truthy value passed.
    ...props // Alert props.
  }
) {
  return (
    <ErrorAlert title={title} autoContrast={autoContrast} onClose={onClose} withCloseButton={!!onClose} {...props}>
      <>
        {children}
        {!!onClick && (
          <Center>
            <ActionIcon
              color='teal'
              aria-label='retry'
              variant='light'
              loading={loading}
              disabled={disabled}
              onClick={onClick}
              autoContrast={autoContrast}
              size={size}
            >
              <IconRefresh />
            </ActionIcon>
          </Center>
        )}
      </>
    </ErrorAlert>
  )
}

QueryErrorAlert.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  autoContrast: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onClose: PropTypes.func
}
