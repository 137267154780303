/**
 * @namespace Contact
 */

/**
 * @typedef {object} Contact.StatusTier
 * @property {int} id
 * @property {string} name
 */

/**
 * @typedef {object} Contact.Status
 * @property {int} id
 * @property {string} name
 * @property {Contact.StatusTier} tier
 */

/**
 * @typedef {object} Contact.ContactInfo
 * @property {string} email
 */

/**
 * @typedef {object} Contact.Applicant
 * @property {int} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} name Inserted
 * @property {string} searchKey Inserted
 * @property {Contact.ContactInfo} contact_information
 * @property {Contact.Status} status
 */

/**
 * @typedef {object} Contact.TemplateCategory
 * @property {?string} name
 * @property {string} tag
 * @property {boolean} isBatteryInvite Inserted
 * @property {boolean} isPhaseInvite Inserted
 */

/**
 * @typedef {object} Contact.EmailTemplate
 * @property {int} id
 * @property {string} title
 * @property {?Contact.TemplateCategory} category
 * @property {boolean} default
 * @property {string} subject new to serialization group
 * @property {string} template new to serialization group
 */

/**
 * @typedef {object} Contact.TextTemplate
 * @property {int} id
 * @property {string} title
 * @property {string} template
 * @property {?Contact.TemplateCategory} category
 */

/**
 * @typedef {object} Contact.EmailMessage
 * @property {int} id
 * @property {?string} send_at
 * @property {?string} template templateId?.toString()
 * @property {string} subject
 * @property {string} body
 * @property {MessageType.Email} type
 */

/**
 * @typedef {object} Contact.TextMessage
 * @property {int} id
 * @property {string} send_at
 * @property {string} template templateId.toString()
 * @property {string} body
 * @property {MessageType.Text} type
 */

/**
 * @typedef {object} Contact.DeprecatedSubmission
 * @property {int} id applicantId
 * @property {string} status
 * @property {string} email
 * @property {string|null|undefined} submitted_at
 */

/**
 * @typedef {object} Contact.DeprecatedRetake
 * @property {Contact.DeprecatedSubmission} submission
 * @property {boolean} retake
 */

/**
 * @typedef {object} Contact.StageAssessment
 * @property {int} id publishedAssessmentId
 * @property {string} name
 * @property {int} index stageIndex
 */

/**
 * @typedef {object} Contact.PassportRetake
 * @property {int} id applicantId
 * @property {boolean} retake
 * @property {?boolean} retakeRequired
 * @property {boolean} started inserted from response - false for neverInvited.
 * @property {boolean} unlock
 * @property {string} phase phaseId inserted from response validatedPhase
 * @property {boolean} startFirstStageInPhase
 * @property {?Contact.StageAssessment} continueOnAssessment
 * @property {?int} lastPhaseId
 * @property {Contact.StageAssessment} firstAssessment
 * @property {?string} submittedAt
 * @property {string} identifier email
 * @property {boolean} locked passport->isLocked()
 */

/**
 * @typedef {Map.<int, Contact.DeprecatedRetake>} Contact.BatteryRetakes <applicantId: Contact.DeprecatedRetake>
 */

/**
 * @typedef {Map.<int, Contact.PassportRetake>} Contact.PhaseRetakes <applicantId: Contact.PassportRetake>
 */

/**
 * @typedef {object} Contact.InviteConfig
 * @property {string} autoComment
 * @property {'-1'|'0'|'1'} collectDemographics
 * @property {boolean} emailOnSubmit
 * @property {?string} expirationDate Unused for source configs for InviteConfigTemplates - see secondsUntilExpirationDate.
 * @property {string} header
 * @property {string} internalNote
 * @property {string} linkPrefix
 * @property {string} message
 * @property {'-1'|'0'|'1'} proctored
 * @property {string} style
 * @property {string} thankYouStyle
 */

/**
 * @typedef {object} Contact.InviteConfigTemplate
 * @property {int} daysUntilDisplayDeadline default 10
 * @property {Contact.InviteConfig} inviteConfig
 * @property {string} phase phaseId
 * @property {?int} secondsUntilExpirationDate
 */

/**
 * @typedef {object} Contact.Battery
 * @property {int} id
 * @property {string} name
 */

/**
 * @typedef {object} Contact.ContactState
 * @property {boolean} isHiringView
 * @property {int} aggregateId isHiringView ? hiringViewId : cycleId. Mutually exclusive, both used to populate cycleIds.
 * @property {int} maxSelectedApplicants
 * @property {int[]} cycleIds
 * @property {Map.<int, Contact.Battery>} batteries <batteryId: Contact.Battery>
 * @property {Map.<int, Contact.Applicant>} applicants <applicantId: Contact.Applicant>
 * @property {Map.<int, Contact.Status>} statuses <statusId: Contact.Status>
 * @property {Map.<int, Contact.EmailTemplate>} emailTemplates <templateId: Contact.EmailTemplate>
 * @property {Map.<int, Contact.TextTemplate>} textTemplates <templateId: Contact.TextTemplate>
 * @property {Map.<int, (Contact.EmailMessage || Contact.TextMessage)>} messages <generatedMessageId: Contact.EmailMessage || Contact.TextMessage>
 * @property {boolean} hideCourtesyLetterRecipients
 * @property {Set.<int>} courtesyLetterRecipients <applicantId>
 * @property {Set.<int>} selectedStatuses <statusId>
 * @property {Map.<int, boolean>} filteredApplicants <applicantId: isSelected> filtered results using selectedStatuses and hideCourtesyLetterRecipients - selected defaults false.
 * @property {Map.<int, Contact.BatteryRetakes>} retakes <batteryId: Contact.BatteryRetakes>
 * @property {Map.<int, Contact.PhaseRetakes>} phaseRetakes <phaseId: Contact.PhaseRetakes>
 * @property {?int} batteryId ap_cycle - 0 is treated the same as null
 * @property {?Contact.InviteConfigTemplate} inviteConfigTemplate
 * @property {int} nextFakeId Meta state field for generating ids.
 */

import { combineApplicantName } from '../cycle/invites/formatUtil';
import { useMemo } from 'react';
import dayjs from 'dayjs';

/**
 * @typedef {object} Contact.JsonApplicant
 * @property {int} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {Contact.ContactInfo} contact_information
 * @property {Contact.Status} status
 */

/**
 * @param {array} batteries
 * @param {Contact.JsonApplicant[]} applicants
 * @param {array} statuses
 * @param {array} emailTemplates
 * @param {array} textTemplates
 * @param {Object.<{id: int, manualLetterSent?: ?string, emailSentAt?: ?string, textSentAt?: ?string}>} courtesyLetterRecipients
 * @param {int[]} cycleIds
 * @param {int} aggregateId hiringView or cycle id
 * @param {int} maxSelectedApplicants
 * @param {boolean} isHiringView
 * @param {?(int[])} preSelectedIds
 * @returns {Contact.ContactState}
 */
export function createInitialContactState (
  {
    batteries,
    applicants,
    statuses,
    emailTemplates,
    textTemplates,
    courtesyLetterRecipients,
    cycleIds,
    aggregateId,
    maxSelectedApplicants,
    isHiringView = false,
    preSelectedIds = null
  }
) {
  console.debug(
    'Initializing contact state',
    {
      batteries,
      applicants,
      statuses,
      emailTemplates,
      textTemplates,
      courtesyLetterRecipients,
      cycleIds,
      aggregateId,
      maxSelectedApplicants,
      isHiringView,
      preSelectedIds
    }
  )
  const firstFakeId = -1
  const messages = new Map()
  messages.set(firstFakeId, {
    id: firstFakeId,
    send_at: null,
    template: null,
    subject: '',
    body: '',
    type: MessageType.Email
  })
  return {
    isHiringView: isHiringView,
    aggregateId: aggregateId,
    maxSelectedApplicants: maxSelectedApplicants,
    cycleIds: cycleIds,
    batteries: new Map(batteries.map(battery => [battery.id, battery])),
    applicants: new Map(
      applicants.map(applicant => [
        applicant.id,
        {
          ...applicant,
          name: combineApplicantName(applicant),
          searchKey: `${applicant.first_name.trim().toLowerCase()} ${applicant.last_name.trim().toLowerCase()} ${applicant.first_name.trim().toLowerCase()} ${applicant.contact_information.email.trim().toLowerCase()}`
        }
      ])
    ),
    statuses: new Map(statuses.map(status => [status.id, status])),
    emailTemplates: new Map(filterCategories(emailTemplates).map(template => [template.id, { ...template, category: template.category ? tagTemplateCategory(template.category) : null }])),
    textTemplates: new Map(filterCategories(textTemplates).map(template => [template.id, { ...template, category: template.category ? tagTemplateCategory(template.category) : null }])),
    messages: messages,
    hideCourtesyLetterRecipients: false,
    courtesyLetterRecipients: new Set(courtesyLetterRecipients.map(recipient => recipient.id)),
    selectedStatuses: new Set(),
    filteredApplicants: new Map(applicants.map(applicant => [applicant.id, !!preSelectedIds?.includes(applicant.id)])),
    retakes: new Map(),
    phaseRetakes: new Map(),
    batteryId: null,
    inviteConfigTemplate: null,
    nextFakeId: firstFakeId - 1
  }
}

/**
 * Removes 'boost-invite' and 'prescreen-completed' category templates.
 * @template T
 * @param {T[]} templates
 * @returns {T[]}
 */
function filterCategories (templates) {
  return templates.filter(template => template.category?.tag !== 'boost-invite' && template.category?.tag !== 'prescreen-completed')
}

/**
 * @param {object} category
 * @param {?string} category.name
 * @param {string} category.tag
 * @returns {Contact.TemplateCategory}
 */
function tagTemplateCategory (category) {
  return {
    ...category,
    isBatteryInvite: tagIsAssessmentInvite(category.tag, true, false),
    isPhaseInvite: tagIsAssessmentInvite(category.tag, false, true)
  }
}

function tagIsAssessmentInvite (tag, allowBattery, allowPhase) {
  return (allowBattery && assessmentTemplateCategoryTags.includes(tag)) || (allowPhase && tagIsPhaseInvite(tag))
}

function tagIsPhaseInvite (tag) {
  return tag && tag.toLowerCase().trim().startsWith(sharedPhaseInvitePrefix)
}

const assessmentTemplateCategoryTags = [
  'proctorfree-invitation',
  'proctorfree-reminder'
]

// const createPhaseInvitePrefix = 'passport-invite-phase-'
// const phaseInviteReminderPrefix = 'passport-invite-reminder-phase-'
const sharedPhaseInvitePrefix = 'passport-invite'

/**
 * @type {{string}}
 */
export const MessageType = {
  Email: 'email',
  Text: 'text'
}

export const ContactStateUpdate = {
  ToggleSelectApplicant: 'toggle-select-applicant',
  ToggleSelectMaxApplicants: 'toggle-select-max-applicants',
  ToggleHideCourtesyLetterApplicants: 'toggle-hide-courtesy-letter-applicants',
  SetSelectStatuses: 'set-select-statuses',
  CreateNewMessage: 'create-new-message',
  EditMessage: 'edit-message',
  EditMessageSendDate: 'edit-message-send-date',
  SetMessageTemplate: 'set-message-template',
  RemoveMessage: 'remove-message',
  UpdateSelectedBattery: 'update-selected-battery',
  UpdatePhaseInviteTemplate: 'update-phase-invite-template',
  UpdateBatteryRetake: 'update-battery-retake',
  UpdatePhaseRetake: 'update-phase-retake',
  UpsertBatterySubmission: 'upsert-battery-submission',
  UpsertPhaseSubmission: 'upsert-phase-submission'
}

/**
 * @param {*&Contact.ContactState} state
 * @param {object} action
 * @param {ContactStateUpdate} action.type
 * @param {Contact.Applicant?} action.applicant
 * @param {(int[])?} action.statusIds
 * @param {string?} action.messageType MessageType
 * @param {int?} action.messageId
 * @param {string?} action.field
 * @param {string?} action.value
 * @param {object?} action.values
 * @param {array?} action.values.neverInvited
 * @param {array?} action.values.continueFromLastPhase
 * @param {array?} action.values.resumePhase
 * @param {array?} action.values.retakes
 * @param {string?} action.values.validatedPhase
 * @param {int?} action.batteryId
 * @param {Contact.InviteConfigTemplate?} action.inviteConfigTemplate
 * @param {int?} action.id Used for deprecatedRetake || phaseRetake actions
 * @param {boolean?} action.retake
 * @param {boolean?} action.unlock
 * @param {int?} action.phaseId
 * @returns {*&Contact.ContactState}
 */
export function contactStateReducer (state, action) {
  switch (action.type) {
    case ContactStateUpdate.ToggleSelectApplicant: {
      console.debug('Toggling selected applicant', action, state)
      const applicant = action.applicant
      const selected = state.filteredApplicants.get(applicant.id)
      if (selected === null) {
        console.error('Toggled applicant not found in filtered results - skipping', { action, state })
        return state
      }
      const newFiltered = new Map(state.filteredApplicants)
      newFiltered.set(applicant.id, !selected)
      return { ...state, filteredApplicants: newFiltered }
    }
    case ContactStateUpdate.ToggleSelectMaxApplicants: {
      console.debug('Toggling select max applicants', action, state)
      if (!state.filteredApplicants.size) {
        console.error('No valid applicants found when toggling max selected - skipping', { action, state })
        return state
      }
      const [unselected, selected] = splitValidApplicants(state.filteredApplicants, state.applicants)
      const maxCount = state.maxSelectedApplicants
      const maxSelected = !unselected.length || (selected.length === maxCount)
      const maxAllowedToAdd = Math.min(Math.max(maxCount - selected.length, 0), unselected.length)
      if (maxSelected) {
        return { ...state, filteredApplicants: new Map([...state.filteredApplicants.keys()].map(applicantId => [applicantId, false])) }
      }
      let newSelectedCount = 0
      const newSelected = new Map(state.filteredApplicants)
      for (const [applicantId, selected] of state.filteredApplicants.entries()) {
        if (!selected) {
          newSelected.set(applicantId, true)
          newSelectedCount += 1
          if (newSelectedCount >= maxAllowedToAdd) {
            break
          }
        }
      }
      return { ...state, filteredApplicants: newSelected }
    }
    case ContactStateUpdate.ToggleHideCourtesyLetterApplicants: {
      console.debug('Toggling hide courtesy letter recipients', action, state)
      const hideRecipients = !state.hideCourtesyLetterRecipients
      if (hideRecipients) {
        const newSelected = new Map()
        for (const [applicantId, selected] of state.filteredApplicants.entries()) {
          if (!state.courtesyLetterRecipients.has(applicantId)) {
            newSelected.set(applicantId, selected)
          }
        }

        return { ...state, hideCourtesyLetterRecipients: hideRecipients, filteredApplicants: newSelected }
      }
      const newSelected = new Map(state.filteredApplicants)
      for (const [applicantId, applicant] of state.applicants.entries()) {
        if (!newSelected.has(applicantId) && applicantStatusPassesFilter(applicant, state.selectedStatuses)) {
          newSelected.set(applicantId, false)
        }
      }
      return { ...state, hideCourtesyLetterRecipients: hideRecipients, filteredApplicants: newSelected }
    }
    case ContactStateUpdate.SetSelectStatuses: {
      console.debug('Setting selected statuses', action, state)
      const statusIds = new Set(action.statusIds)
      const hideLetterRecipients = state.hideCourtesyLetterRecipients
      const newSelected = new Map()
      for (const [applicantId, selected] of state.filteredApplicants.entries()) {
        const passesRecipientFilter = !hideLetterRecipients || !state.courtesyLetterRecipients.has(applicantId)
        if (passesRecipientFilter && applicantStatusPassesFilter(state.applicants.get(applicantId), statusIds)) {
          newSelected.set(applicantId, selected)
        }
      }
      for (const [applicantId, applicant] of state.applicants.entries()) {
        const notPreviouslySelected = !state.filteredApplicants.has(applicantId)
        const passesRecipientFilter = !hideLetterRecipients || !state.courtesyLetterRecipients.has(applicantId)
        if (notPreviouslySelected && passesRecipientFilter && applicantStatusPassesFilter(applicant, statusIds)) {
          newSelected.set(applicantId, false)
        }
      }
      return { ...state, selectedStatuses: statusIds, filteredApplicants: newSelected }
    }
    case ContactStateUpdate.CreateNewMessage: {
      console.debug('Creating new message', action, state)
      const newMessageType = action.messageType
      const newMessages = new Map(state.messages)
      const nextFakeId = state.nextFakeId
      if (newMessageType === MessageType.Text) {
        newMessages.set(nextFakeId, {
          id: nextFakeId,
          type: MessageType.Text,
          send_at: dayjs().add(1, 'day').toDate(),
          template: '',
          body: ''
        })
      } else {
        newMessages.set(nextFakeId, {
          id: nextFakeId,
          type: MessageType.Email,
          send_at: null,
          template: null,
          subject: '',
          body: ''
        })
      }
      return { ...state, messages: newMessages, nextFakeId: nextFakeId - 1 }
    }
    case ContactStateUpdate.EditMessage: {
      console.debug('Editing message', action, state)
      const message = state.messages.get(action.messageId)
      if (!message) {
        console.error('Message not found in state for update - skipping', { action, state })
        return state
      }
      const newMessages = new Map(state.messages)
      newMessages.set(message.id, { ...message, [action.field]: action.value })
      return { ...state, messages: newMessages }
    }
    case ContactStateUpdate.EditMessageSendDate: {
      console.debug('Editing message send date', action, state)
      const message = state.messages.get(action.messageId)
      if (!message || (!action.value && (message.type === MessageType.Text))) { // TODO [instant text] enable unscheduled texts front-and-back
        console.error('Invalid action parameters for state update - skipping', { action, state })
        return state
      }
      if (message.send_at === action.value) {
        console.debug('No-op state update for message send date - skipping', { action, state })
        return state
      }
      const newMessages = new Map(state.messages)
      newMessages.set(message.id, { ...message, send_at: action.value })
      return { ...state, messages: newMessages }
    }
    case ContactStateUpdate.SetMessageTemplate: {
      console.debug('Setting message template', action, state)
      const message = state.messages.get(action.messageId)
      if (!message) {
        console.error('Invalid action parameters for state update - skipping', { action, state })
        return state
      }
      if (message.template === action.value) {
        console.debug('No-op state update for message template - skipping', { action, state })
        return state
      }
      const isTextMessage = message.type === MessageType.Text
      const newMessages = new Map(state.messages)
      const updatedMessage = { ...message, template: isTextMessage ? (action.value || '') : action.value }
      if (!action.value) {
        updatedMessage.body = ''
        if (!isTextMessage) {
          updatedMessage.subject = ''
        }
        newMessages.set(message.id, updatedMessage)
        if (state.inviteConfigTemplate || state.batteryId) {
          const [showBattery, showPhase] = calculateShowPhaseOrBattery(newMessages, state.emailTemplates, state.textTemplates)
          return { ...state, messages: newMessages, batteryId: showBattery ? state.batteryId : null, inviteConfigTemplate: showPhase ? state.inviteConfigTemplate : null }
        }
        return { ...state, messages: newMessages }
      }

      const intTemplateId = parseInt(action.value)
      const template = isTextMessage ? state.textTemplates.get(intTemplateId) : state.emailTemplates.get(intTemplateId)
      if (!template) {
        console.error('Selected template not found in state for update - skipping', { action, state })
        return state
      }

      updatedMessage.body = template.template
      if (!isTextMessage) {
        updatedMessage.subject = template.subject
      }
      newMessages.set(message.id, updatedMessage)
      return { ...state, messages: newMessages }
    }
    case ContactStateUpdate.RemoveMessage: {
      console.debug('Removing message', action, state)
      const message = state.messages.get(action.messageId)
      if (!message) {
        console.error('Message not found for state update - skipping', { action, state })
        return state
      }
      const newMessages = new Map()
      for (const [messageId, message] of state.messages.entries()) {
        if (messageId !== action.messageId) {
          newMessages.set(messageId, message)
        }
      }
      if (state.inviteConfigTemplate || state.batteryId) {
        const [showBattery, showPhase] = calculateShowPhaseOrBattery(newMessages, state.emailTemplates, state.textTemplates)
        return { ...state, messages: newMessages, batteryId: showBattery ? state.batteryId : null, inviteConfigTemplate: showPhase ? state.inviteConfigTemplate : null }
      }
      return { ...state, messages: newMessages }
    }
    case ContactStateUpdate.UpdateSelectedBattery: {
      console.debug('Updating selected battery', action, state)
      const batteryId = action.batteryId
      if (batteryId === state.batteryId) {
        console.debug('No-op state update for battery - skipping', { action, state })
        return state
      }

      return { ...state, batteryId: batteryId, inviteConfigTemplate: batteryId ? null : state.inviteConfigTemplate }
    }
    case ContactStateUpdate.UpdatePhaseInviteTemplate: {
      console.debug('Updating phase invite template', action, state)
      const inviteConfigTemplate = action.inviteConfigTemplate
      if (inviteConfigTemplate === state.inviteConfigTemplate) {
        console.debug('No-op state update for template - skipping', { action, state })
        return state
      }

      return { ...state, inviteConfigTemplate: inviteConfigTemplate, batteryId: inviteConfigTemplate ? null : state.batteryId }
    }
    case ContactStateUpdate.UpdateBatteryRetake: {
      console.debug('Updating legacy retake', action, state)
      const batteryId = action.batteryId
      const retakeId = action.id
      const batterySubmissions = state.retakes.get(batteryId)
      const submission = batterySubmissions?.get(retakeId)
      if (!submission) {
        console.error('Deprecated retake not found for state update - skipping', { action, state })
        return state
      }
      const doRetake = action.retake ?? submission.retake
      if (doRetake === submission.retake) {
        console.debug('No-op state update for retake - skipping', { action, state })
        return state
      }
      const newDeprecatedRetakes = new Map(state.retakes)
      const newBatterySubmissions = new Map(batterySubmissions)
      newBatterySubmissions.set(retakeId, { ...submission, retake: doRetake })
      newDeprecatedRetakes.set(batteryId, newBatterySubmissions)
      return { ...state, retakes: newDeprecatedRetakes }
    }
    case ContactStateUpdate.UpdatePhaseRetake: {
      console.debug('Updating phase retake', action, state)
      const phaseId = action.phaseId
      const retakeId = action.id
      const phaseSubmissions = state.phaseRetakes.get(phaseId)
      const submission = phaseSubmissions?.get(retakeId)
      if (!submission) {
        console.error('Phase retake not found for state update - skipping', { action, state })
        return state
      }
      const doRetake = action.retake ?? submission.retake
      const doUnlock = action.unlock ?? submission.unlock
      if ((doRetake === submission.retake) && (doUnlock === submission.unlock)) {
        console.debug('No-op state update for retake - skipping', { action, state })
        return state
      }
      const newPhaseRetakes = new Map(state.phaseRetakes)
      const newPhaseSubmissions = new Map(phaseSubmissions)
      newPhaseSubmissions.set(retakeId, { ...submission, retake: doRetake, unlock: doUnlock })
      newPhaseRetakes.set(phaseId, newPhaseSubmissions)
      return { ...state, phaseRetakes: newPhaseRetakes }
    }
    case ContactStateUpdate.UpsertBatterySubmission: {
      console.debug('Inserting battery submissions', action, state)
      const battery = action.batteryId
      const updatedSubmissions = action.values
      const previousSubmissions = state.retakes.get(battery) ?? new Map()
      const newBatteryMap = new Map(state.retakes)
      const newRetakes = new Map(previousSubmissions)
      for (const retake of updatedSubmissions) {
        newRetakes.set(
          retake.id,
          {
            submission: retake,
            retake: newRetakes.get(retake.id)?.retake ?? true
          }
        )
      }
      newBatteryMap.set(battery, newRetakes)
      return { ...state, retakes: newBatteryMap }
    }
    case ContactStateUpdate.UpsertPhaseSubmission: {
      console.debug('Inserting phase submissions', action, state)
      const phase = parseInt(action.values.validatedPhase)
      const updatedSubmissions = action.values
      const previousSubmissions = state.phaseRetakes.get(phase) ?? new Map()
      const newPhasesMap = new Map(state.phaseRetakes)
      const newRetakes = new Map(previousSubmissions)
      for (const retake of updatedSubmissions.retakes) {
        newRetakes.set(
          retake.id,
          {
            ...(newRetakes.get(retake.id) ?? { unlock: retake.locked, retake: true, retakeRequired: true }),
            ...retake,
            started: true
          }
        )
      }
      for (const resumePhase of updatedSubmissions.resumePhase) {
        newRetakes.set(
          resumePhase.id,
          {
            ...(newRetakes.get(resumePhase.id) ?? { unlock: resumePhase.locked, retake: false, retakeRequired: null }),
            ...resumePhase,
            started: true
          }
        )
      }
      for (const continueFromLastPhase of updatedSubmissions.continueFromLastPhase) {
        if (continueFromLastPhase.locked) {
          newRetakes.set(
            continueFromLastPhase.id,
            {
              ...(newRetakes.get(continueFromLastPhase.id) ?? { unlock: true }),
              ...continueFromLastPhase,
              started: false,
              retake: false,
              retakeRequired: false
            }
          )
        } else if (newRetakes.has(continueFromLastPhase.id)) {
          newRetakes.delete(continueFromLastPhase.id)
        }
      }
      for (const neverInvited of updatedSubmissions.neverInvited) {
        if (neverInvited.locked) {
          newRetakes.set(
            neverInvited.id,
            {
              ...(newRetakes.get(neverInvited.id) ?? { unlock: true }),
              ...neverInvited,
              started: false,
              retake: false,
              retakeRequired: false
            }
          )
        } else if (newRetakes.has(neverInvited.id)) {
          newRetakes.delete(neverInvited.id)
        }
      }
      newPhasesMap.set(phase, newRetakes)
      return { ...state, phaseRetakes: newPhasesMap }
    }
    default: {
      console.error('Unknown contact state update action type - skipping update.', action, state)
      return state
    }
  }
}

/**
 * @param {Map.<int, boolean>} filteredApplicants
 * @param {Map.<int, Contact.Applicant>} applicantData
 * @returns {[Contact.Applicant[], Contact.Applicant[]]}
 */
export function useContactValidApplicants (filteredApplicants, applicantData) {
  return useMemo(() => {
    const [newUnselected, newSelected] = splitValidApplicants(filteredApplicants, applicantData)
    console.debug(
      'Calculated new contact valid applicants',
      { filteredApplicants, newSelected, newUnselected, applicantData }
    )
    return [newUnselected, newSelected]
  }, [filteredApplicants, applicantData])
}

/**
 * @param {Map.<int, boolean>} filteredApplicants
 * @param {Map.<int, Contact.Applicant>} applicantData
 * @returns {[Contact.Applicant[], Contact.Applicant[]]} [unselected, selected]
 */
function splitValidApplicants (filteredApplicants, applicantData) {
  const newUnselected = []
  const newSelected = []
  for (const [applicantId, selected] of filteredApplicants.entries()) {
    if (selected) {
      newSelected.push(applicantData.get(applicantId))
    } else {
      newUnselected.push(applicantData.get(applicantId))
    }
  }
  return [newUnselected, newSelected]
}

/**
 * @param {Contact.Applicant} applicant
 * @param {Set.<int>} selectedStatuses
 * @returns {boolean}
 */
function applicantStatusPassesFilter (applicant, selectedStatuses) {
  return !selectedStatuses.size || selectedStatuses.has(applicant.status.id)
}

/**
 * @param {Map.<int, (Contact.EmailMessage || Contact.TextMessage)>} messages
 * @param {Map.<int, Contact.EmailTemplate>} emailTemplates
 * @param {Map.<int, Contact.TextTemplate>} textTemplates
 * @returns {[boolean, boolean]}
 */
export function calculateShowPhaseOrBattery (messages, emailTemplates, textTemplates) {
  let newShowPhase = false
  let newShowBattery = false
  for (const message of [...messages.values()]) {
    if (message.template) {
      const templateId = parseInt(message.template)
      const template = message.type === MessageType.Text ? textTemplates.get(templateId) : emailTemplates.get(templateId)
      newShowPhase = newShowPhase || !!template.category?.isPhaseInvite
      newShowBattery = newShowBattery || !!template.category?.isBatteryInvite
      if (newShowPhase && newShowBattery) {
        console.error('Calculated show phase or battery state should now be impossible', { newShowPhase, newShowBattery })
        break
      }
    }
  }
  return [newShowBattery, newShowPhase]
}
