import React, { useMemo } from 'react'
import ContactMultipleApp from './ContactMultipleApp'
import CONSTANT from '../../../constants'
import { useParams } from 'react-router-dom'
import {
  useGetCycleApplicantsQuery, useGetCycleEmailTemplatesQuery,
  useGetCycleQuery, useGetCycleStatusesQuery,
  useGetCycleTextTemplatesQuery, useGetCycleCourtesyLetterRecipientsQuery
} from '../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { CyclesContextProvider } from './CyclesContextProvider';
import { ContactStateProvider } from './ContactStateProvider';

export default function ContactCycleApp () {
  const max = CONSTANT.MAX_CONTACT_APPLICANTS
  const { cycleId: id } = useParams()
  const { data: cycle, error, isLoading } = useGetCycleQuery(id)
  const { data: applicants, error: applicantError, isLoading: applicantLoading } = useGetCycleApplicantsQuery({ cycleId: id, limit: -1 })
  const { data: courtesyLetterRecipients, error: recipientsError, isLoading: recipientsLoading } = useGetCycleCourtesyLetterRecipientsQuery(id)
  const { data: statuses, error: statusesError, isLoading: statusesLoading } = useGetCycleStatusesQuery({ cycleId: id })
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetCycleEmailTemplatesQuery(id)
  const { data: textTemplates, error: textTemplatesError, isLoading: textTemplatesLoading } = useGetCycleTextTemplatesQuery(id)

  const batteries = useMemo(() => {
    return cycle?.cycle_batteries.filter(linkedBattery => linkedBattery.active).map(linkedBattery => linkedBattery.cycle) ?? []
  }, [cycle])

  const intId = parseInt(id)
  const cycles = useMemo(() => {
    return [intId]
  }, [intId])

  if (error || applicantError || recipientsError || statusesError || emailTemplatesError || textTemplatesError) {
    return <ErrorLoading />
  }

  if (isLoading || applicantLoading || recipientsLoading || statusesLoading || emailTemplatesLoading || textTemplatesLoading) {
    return <Loading />
  }

  const cycleId = cycle?.id ?? (isNaN(parseInt(id)) ? null : parseInt(id))
  return (
    <CyclesContextProvider cycle={cycleId}>
      <ContactStateProvider
        batteries={batteries}
        applicants={applicants.data}
        statuses={statuses.items}
        emailTemplates={emailTemplates.data}
        textTemplates={textTemplates.data}
        courtesyLetterRecipients={courtesyLetterRecipients.data}
        cycleIds={cycles}
        aggregateId={intId}
        maxSelectedApplicants={max}
        isHiringView={false}
      >
        <ContactMultipleApp
          onSuccessRedirect={`/cycles/${id}/results`}
        />
    </ContactStateProvider>
    </CyclesContextProvider>
  )
}
