import { useDispatch } from 'react-redux'
import React, { useCallback } from 'react'
import { closeModal } from './detailViewSlice'
import { Modal } from '@mantine/core'

export default function DetailViewApplicantModal ({ modal, title = '', applicantId, children, ...props }) {
  const dispatch = useDispatch()

  const handleOnClose = useCallback(() => {
    dispatch(closeModal({ modal }))
  }, [dispatch, modal])

  return (
    <Modal.Root opened={applicantId} onClose={handleOnClose}>
    <Modal.Overlay />
    <Modal.Content>
      <Modal.Header>
        <Modal.Title fw={700} fz='lg'>{title}</Modal.Title>
        <Modal.CloseButton />
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal.Content>
  </Modal.Root>
  )
}
