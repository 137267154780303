import { ApiMethods, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'

export const applicantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApplicant: builder.query({
      query: id => `applicants/${id}`,
      providesTags: (result, error, id) => [{ type: ApiTags.Applicants, id: id }]
    }),
    getRezviewContent: builder.query({
      query: ({ id, redacted, ...params }) => {
        let url = `applicants/${id}/rezview${paramsToQuery(params)}`
        if (redacted) {
          url = `applicants/${id}/rezview/redacted${paramsToQuery(params)}`
        }
        return { url }
      },
      providesTags: (result, error, id) => [{ type: ApiTags.Applicants, id: id }]
    }),
    getFlagsContent: builder.query({
      query: id => `applicants/${id}/flags`,
      providesTags: (result, error, id) => [{ type: ApiTags.Applicants, id: id }]
    }),
    editStatus: builder.mutation({
      query: ({ status, applicants, hiredDate, cycleId }) => {
        const formData = new FormData()
        formData.append('status-name', status)
        for (let i = 0; i < applicants.length; i++) {
          formData.append('applicant-ids[]', applicants[i]);
        }
        formData.append('hired-date', hiredDate ?? '')
        console.debug('Dispatching edit status action.', { formData, status, applicants, hiredDate, cycleId })
        return {
          url: 'statuses/applicants/bulkedit',
          method: ApiMethods.Post,
          body: formData
        }
      },
      invalidatesTags: (result, error, { applicants: applicantsParam }) => [
        ...applicantsParam.map(applicantId => ({ type: ApiTags.Applicants, id: applicantId }))
      ]
    }),
    bulkComment: builder.mutation({
      query: ({ applicants, content, pinned, cycleId }) => {
        const data = applicants.map(applicantId => ({ applicant: applicantId, content: content, pinned: pinned ? 1 : 0 }))
        console.debug('Dispatching bulkComment action.', { data, applicants, content, pinned, cycleId })
        return {
          url: 'comments',
          method: ApiMethods.Post,
          body: data
        }
      },
      invalidatesTags: (result, error, { applicants: applicantsParam }) => [
        ...applicantsParam.map(applicantId => ({ type: ApiTags.Applicants, id: applicantId }))
      ]
    }),
    editModuleScore: builder.mutation({
      query: ({ cycleId, applicantId, moduleId, ...body }) => ({
        url: `applicants/cycle/${cycleId}/applicant/${applicantId}/module/${moduleId}/update`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { applicantId: applicantIdParam }) => [
        { type: ApiTags.Applicants, id: applicantIdParam }
      ]
    }),
    bulkClearScores: builder.mutation({
      query: ({ cycleId, ...body }) => ({
        url: `applicants/cycle/${cycleId}/scores/clear${paramsToQuery(body)}`,
        method: ApiMethods.Post
      }),
      invalidatesTags: (result, error, { applicantIds: applicantIdsParam }) => [
        ...applicantIdsParam.map(applicantId => ({ type: ApiTags.Applicants, id: applicantId }))
      ]
    }),
    previewEmailTemplate: builder.query({
      query: ({ applicantId, subject, body, ...json }) => ({
        url: `applicants/${applicantId}/preview-template`,
        method: ApiMethods.Post,
        body: { ...json, subject: subject || '', body: body || '' }
      }),
      providesTags: (result, error, { applicantId }) =>
        [{ type: ApiTags.Applicants, id: applicantId }]
    }),
    previewTextTemplate: builder.query({
      query: ({ applicantId, body, ...json }) => ({
        url: `applicants/${applicantId}/preview-text-template`,
        method: ApiMethods.Post,
        body: { ...json, body: body || '' }
      }),
      providesTags: (result, error, { applicantId }) =>
        [{ type: ApiTags.Applicants, id: applicantId }]
    })
  })
})

export const {
  useGetApplicantQuery,
  useGetRezviewContentQuery,
  useGetFlagsContentQuery,
  useEditStatusMutation,
  useBulkCommentMutation,
  useEditModuleScoreMutation,
  useBulkClearScoresMutation,
  usePreviewEmailTemplateQuery,
  usePreviewTextTemplateQuery
} = applicantsApiSlice
