/* eslint react/prop-types: 0 */
import React from 'react'

import * as styles from './SelectTable.module.scss'
import { Flex, Text } from '@mantine/core';

export default function SelectTable ({ applicants, callback, recipients }) {
  return (
    <div className={styles.container}>
      {
        applicants.map((applicant) => (

          <div key={`applicant-${applicant.id}`} className={styles.element} onClick={() => callback(applicant)}>
            <Flex justify='flex-end' align='flex-end' direction='column' wrap='nowrap' style={{ float: 'right' }}>
              <Text ta='right' fz='sm' c='dimmed' mb='md'>{applicant.status.name}</Text>
              {!!recipients.size && recipients.has(applicant.id) && (
                <Text ta='right' fz='xs' c='dimmed' td='underline'>Courtesy Letter Recipient</Text>
              )}
            </Flex>
            <p className={styles.name}>{applicant.first_name} {applicant.last_name}</p>
            <p className={styles.email}>{applicant.contact_information.email}</p>
          </div>
        ))
      }
    </div>
  )
}
