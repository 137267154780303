import React from 'react'
import { IconSquareX } from '@tabler/icons-react'
import PropTypes from 'prop-types'
import CustomAlert from './CustomAlert'

export default function ErrorAlert ({ children, title = 'Error!', ...props }) {
  return <CustomAlert title={title} icon={<IconSquareX />} color='red' {...props}>{children}</CustomAlert>
}

ErrorAlert.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}
