import React, { useMemo } from 'react'
import ContactMultipleApp from './ContactMultipleApp'
import _ from 'lodash'
import CONSTANT from '../../../constants'
import { useParams } from 'react-router-dom'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import {
  useGetHiringViewApplicantsQuery, useGetHiringViewBatteriesQuery, useGetHiringViewCourtesyLetterRecipientsQuery,
  useGetHiringViewDetailQuery, useGetHiringViewEmailTemplatesQuery, useGetHiringViewTextTemplatesQuery
} from '../../../redux/query/hire/hiringViewsApi.slice'
import { CyclesContextProvider } from './CyclesContextProvider';
import { ContactStateProvider } from './ContactStateProvider';

export default function ContactHiringViewApp () {
  const max = CONSTANT.MAX_CONTACT_APPLICANTS
  const { id } = useParams()
  const { data: hiringViewDetail, error, isLoading } = useGetHiringViewDetailQuery(id)
  const { data: applicants, error: applicantError, isLoading: applicantLoading } = useGetHiringViewApplicantsQuery({ viewId: id, limit: -1 })
  const { data: courtesyLetterRecipients, error: recipientsError, isLoading: recipientsLoading } = useGetHiringViewCourtesyLetterRecipientsQuery(id)
  const { data: batteries, error: batteriesError, isLoading: batteriesLoading } = useGetHiringViewBatteriesQuery(id)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetHiringViewEmailTemplatesQuery(id)
  const { data: textTemplates, error: textTemplatesError, isLoading: textTemplatesLoading } = useGetHiringViewTextTemplatesQuery(id)

  const statuses = useMemo(() => {
    return !hiringViewDetail ? [] : _.unionBy(..._.map(hiringViewDetail.cycles, cycle => cycle.status_set.statuses), 'name')
  }, [hiringViewDetail])

  const hiringViewCycles = hiringViewDetail?.cycles
  const cycleIds = useMemo(() => {
    return hiringViewCycles?.map(cycle => cycle.id) ?? []
  }, [hiringViewCycles])

  if (error || applicantError || recipientsError || batteriesError || emailTemplatesError || textTemplatesError) {
    return <ErrorLoading />
  }

  if (isLoading || applicantLoading || recipientsLoading || batteriesLoading || emailTemplatesLoading || textTemplatesLoading) {
    return <Loading />
  }

  const intId = parseInt(id)

  return (
    <CyclesContextProvider cycles={cycleIds}>
      <ContactStateProvider
        batteries={batteries.data}
        applicants={applicants.data}
        statuses={statuses}
        emailTemplates={emailTemplates.data}
        textTemplates={textTemplates.data}
        courtesyLetterRecipients={courtesyLetterRecipients.data}
        cycleIds={cycleIds}
        aggregateId={intId}
        maxSelectedApplicants={max}
        isHiringView={true}
      >
        <ContactMultipleApp
          onSuccessRedirect={`/hire/report-results/hiring-views/${id}/view`}
        />
      </ContactStateProvider>
    </CyclesContextProvider>
  )
}
