import React, { useMemo } from 'react'
import ApplicantPanel from './ApplicantPanel'
import Form from './Form'
import { Grid, SimpleGrid } from '@mantine/core'
import {
  useGetCycleEmailTemplatesQuery,
  useGetCycleQuery,
  useGetCycleTextTemplatesQuery
} from '../../../redux/query/hire/cyclesApi.slice'
import { useParams } from 'react-router-dom'
import { useGetApplicantQuery } from '../../../redux/query/hire/applicantsApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { CyclesContextProvider } from './CyclesContextProvider';
import { ContactStateProvider } from './ContactStateProvider';

const courtesyLetterRecipients = []
const statuses = []

export default function ContactApplicantApp () {
  const { cycleId: id, applicantId } = useParams()
  const { data: cycle, error: cycleError, isLoading: cycleLoading } = useGetCycleQuery(id)
  const { data: applicant, error: applicantError, isLoading: applicantLoading } = useGetApplicantQuery(applicantId)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetCycleEmailTemplatesQuery(id)
  const { data: textTemplates, error: textTemplatesError, isLoading: textTemplatesLoading } = useGetCycleTextTemplatesQuery(id)

  const applicants = useMemo(() => {
    return applicant ? [applicant] : []
  }, [applicant])

  const batteries = useMemo(() => {
    return cycle?.cycle_batteries.filter(linkedBattery => linkedBattery.active).map(linkedBattery => linkedBattery.cycle) ?? []
  }, [cycle])

  const intId = parseInt(id)
  const cycles = useMemo(() => {
    return [intId]
  }, [intId])

  const selectedApplicantIds = useMemo(() => {
    return (!applicantId || isNaN(parseInt(applicantId))) ? [] : [parseInt(applicantId)]
  }, [applicantId])

  if (cycleError || applicantError || emailTemplatesError || textTemplatesError) {
    return <ErrorLoading />
  }

  if (cycleLoading || applicantLoading || emailTemplatesLoading || textTemplatesLoading) {
    return <Loading />
  }

  const cycleId = cycle?.id ?? (isNaN(parseInt(id)) ? null : parseInt(id))
  return (
    <CyclesContextProvider cycle={cycleId}>
      <Grid>
        <Grid.Col sm={12} lg={10}>
          <SimpleGrid cols={1}>
            <ApplicantPanel name={applicant.first_name} email={applicant.contact_information.email} />
            <ContactStateProvider
              batteries={batteries}
              applicants={applicants}
              statuses={statuses}
              emailTemplates={emailTemplates.data}
              textTemplates={textTemplates.data}
              courtesyLetterRecipients={courtesyLetterRecipients}
              cycleIds={cycles}
              aggregateId={intId}
              maxSelectedApplicants={1}
              isHiringView={false}
              preSelectedIds={selectedApplicantIds}
            >
              <Form
                onSuccessRedirect={`/cycles/${id}/results`}
              />
            </ContactStateProvider>
          </SimpleGrid>
        </Grid.Col>
      </Grid>
    </CyclesContextProvider>
  )
}
