/* eslint react/prop-types: 0 */
import { DateInput } from '@mantine/dates'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useContext } from 'react'
import { ContactStateUpdate } from './ContactState';
import { ContactDispatchContext } from './ContactContexts';

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function DateSelection ({ messageId, messageSendAt, form, index }) {
  const dispatch = useContext(ContactDispatchContext)
  return (
    <DateInput
      label='Sending date'
      withAsterisk
      popoverProps={{ withinPortal: false }}
      {...form.getInputProps(`messages.${index}.send_at`)}
      value={messageSendAt}
      onChange={(value) => dispatch({ type: ContactStateUpdate.EditMessageSendDate, messageId: messageId, value: value })}
      minDate={dayjs().add(1, 'day').toDate()}
      maxDate={dayjs().add(1, 'year').toDate()}
    />
  )
}
