/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useEffect, useState } from 'react'
import { isNotEmpty, useForm } from '@mantine/form'
import { Button, Grid, Group, Select, SimpleGrid, TextInput } from '@mantine/core'
import EmailEditor from '../../core/email/EmailEditor'
import { getOrganizationLocations, getOrganizations } from '../../../js/api/organization_repository'
import { createTemplate, editTemplate, getCategories, getTemplate } from '../../../js/api/email_template_repository'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';

export default function EmailTemplateEditor () {
  const { id } = useParams()
  const [organizations, setOrganizations] = useState([])
  const [categories, setCategories] = useState([])
  const [locations, setLocations] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      title: '',
      platform: '0',
      organization: null,
      location: null,
      category: null,
      subject: '',
      body: ''
    },
    validate: {
      title: isNotEmpty('Title cannot be empty'),
      subject: isNotEmpty('Subject cannot be empty'),
      body: value => value !== '' && value !== '<p></p>' ? null : 'Body cannot be empty'
    },
    transformValues: (values) => ({
      ...values,
      platform: parseInt(values.platform),
      location: values.location ? parseInt(values.location) : null,
      organization: values.organization ? parseInt(values.organization) : null,
      category: values.category ? parseInt(values.category) : null
    })
  })

  useEffect(() => {
    if (id !== undefined) {
      getTemplate(id).then(template => {
        form.setValues({
          title: template.title,
          subject: template.subject,
          body: template.template,
          platform: template.type.toString(),
          location: template.store?.id.toString() ?? null,
          organization: template.client?.id.toString() ?? null,
          category: template.category?.id.toString() ?? null
        })
        form.resetDirty()
      })
    }
  }, [id])

  useEffect(() => {
    getOrganizations({ limit: 0 }).then(collection => {
      setOrganizations(collection?.items ?? [])
    })
    getCategories(0).then(collection => setCategories(collection?.items ?? []))
  }, [])

  useEffect(() => {
    if (form.values.organization === null) {
      setLocations([])
    } else {
      const cancelToken = axios.CancelToken
      const cancelSource = cancelToken.source()
      getOrganizationLocations(form.values.organization, 0, cancelSource.token).then(collection => { if (collection) setLocations(collection.items) })
      return () => cancelSource.cancel()
    }
  }, [form.values.organization])

  const submit = values => {
    setIsSubmitting(true)

    if (id !== undefined) {
      editTemplate(id, values)
        .then(() => {
          window.location.href = 'build/email/templates'
        })
        .catch(err => {
          if (err.response.status === 400 && err.response.data?.type === 'validation_error') {
            _.forEach(err.response.data.errors, error => {
              showNotification({
                title: 'Something went wrong',
                message: error,
                color: 'red',
                autoClose: 3000
              })
            })
          } else {
            showNotification({
              message: 'Something went wrong',
              color: 'red',
              autoClose: 3000
            })
          }

          setIsSubmitting(false)
        })
    } else {
      createTemplate(values)
        .then(() => {
          window.location.href = 'build/email/templates'
        })
        .catch(err => {
          if (err.response.status === 400 && err.response.data?.type === 'validation_error') {
            _.forEach(err.response.data.errors, error => {
              showNotification({
                title: 'Something went wrong',
                message: error,
                color: 'red',
                autoClose: 3000
              })
            })
          } else {
            showNotification({
              message: 'Something went wrong',
              color: 'red',
              autoClose: 3000
            })
          }

          setIsSubmitting(false)
        })
    }
  }

  const onContentUpdate = useCallback((newContent) => {
    form.setFieldValue('body', newContent)
  }, [])

  return (
    <Grid>
      <Grid.Col span={{ sm: 12, lg: 8 }}>
        <form onSubmit={form.onSubmit(values => submit(values))}>
          <SimpleGrid cols={1} verticalSpacing='xl'>
            <TextInput label='Title' {...form.getInputProps('title')} withAsterisk/>
            <Group grow>
              <Select
                label='Platform'
                data={[
                  { value: '0', label: 'Portal' },
                  { value: '1', label: 'SkillBuilder' }
                ]}
                {...form.getInputProps('platform')}
                allowDeselect={false}
                withAsterisk
              />
              <Select
                label='Organization'
                placeholder='Pick One'
                searchable
                clearable
                data={organizations?.map(organization => ({ value: organization.id.toString(), label: organization.name })) ?? []}
                {...form.getInputProps('organization')}
                onChange={id => {
                  form.setFieldValue('organization', id)
                  form.setFieldValue('location', null)
                }}
              />
            </Group>
            <Group grow>
              <Select
                label='Location'
                placeholder='Pick One'
                searchable
                clearable
                data={locations?.map(location => ({ value: location.id.toString(), label: location.name })) ?? []}
                {...form.getInputProps('location')}
              />
              <Select
                label='Category'
                placeholder='Pick One'
                searchable
                clearable
                data={categories?.map(category => ({ value: category.id.toString(), label: category.name })) ?? []}
                {...form.getInputProps('category')}
              />
            </Group>
            <TextInput label='Subject' {...form.getInputProps('subject')} withAsterisk/>
            <EmailEditor
              onUpdate={onContentUpdate}
              content={form.values.body}
              errors={form.errors.body}
              platform={parseInt(form.values.platform)}
              category={form.values.category ? parseInt(form.values.category) : form.values.category}
            />
            <Group gap='xs'>
              <Button type='submit' color='success' loading={isSubmitting}>Save</Button>
              <Button color='gray.6' onClick={() => navigate('/build/email/templates')}>Cancel</Button>
            </Group>
          </SimpleGrid>
        </form>
      </Grid.Col>
    </Grid>
  )
}
