import { createContext } from 'react';

/**
 * @namespace ContactContexts
 */

/**
 * @typedef {int[]} ContactContexts.CyclesContext
 */

/**
 * @type {ContactContexts.CyclesContext}
 */
export const defaultCyclesContext = []

/**
 * @type {React.Context<ContactContexts.CyclesContext>}
 */
export const CyclesContext = createContext(defaultCyclesContext)

/**
 * @type {React.Context<?Contact.ContactState>}
 */
export const ContactContext = createContext(null)

/**
 * @type {React.Context<?Function>}
 */
export const ContactDispatchContext = createContext(null)

/**
 * @type {React.Context<boolean>}
 */
export const ContactQueryingContext = createContext(true)

/**
 * @type {React.Context<boolean>}
 */
export const ContactQueryErrorContext = createContext(true)
